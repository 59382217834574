<template>
  <div>
    <v-combobox
      :loading="isLoading"
      :search-input.sync="search"
      :filter="customFilter"
      hide-no-data
      single-line
      hide-details
      clearable
      item-value="id"
      :label="searchLabel"
      append-icon="mdi-database-search"
      return-object
      :color="theme.comboBox"
      @click:clear="clear"
      @change="selectNew"
      @keypress="checkLength"
      :items="items"
      item-text="ItemName"
    ></v-combobox>
    <v-card-text v-if="minimumCharacters">
      Please type at least two characters
    </v-card-text>
    <v-card-text v-else-if="maximumCharacters">
      You've reached maximum number of characters
    </v-card-text>
    <v-card-text v-else-if="noResultsMessage">
      No results...
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  maximumCharactersText,
  lettersAndNumbersCheck,
  validCharacterCheck,
  noResultsText
} from "@/utils/search-texts.js";

export default {
  props: ["selectNew", "searchLabel", "theme"],
  data: () => ({
    searchItems: [],
    isLoading: false,
    noResults: false,
    search: null,
    numberOfLoadings: 0,
  }),
  computed: {
    ...mapState("databaseSearch", ["valueLimit", "minSearchLength"]),
    items() {
      return this.searchItems.map(entry => {
        const proposedItem = entry.name;

        const ItemName =
          proposedItem.length > this.valueLimit
            ? proposedItem.slice(0, this.valueLimit) + "..."
            : proposedItem;

        return Object.assign({}, entry, { ItemName });
      });
    },
    minimumCharacters() {
      return lettersAndNumbersCheck(
        this.isLoading,
        this.search,
        this.minSearchLength
      );
    },
    maximumCharacters() {
      return maximumCharactersText(this.search, 100);
    },
    noResultsMessage() {
      return (
        noResultsText(this.isLoading, this.search, this.items) &&
        this.noResults == true
      );
    },
  },
  watch: {
    search(val) {
      if (validCharacterCheck(val, this.minSearchLength)) {
        this.filterEntities(val);
      } else {
        this.searchItems = [];
      }
    }
  },
  methods: {
    ...mapMutations("searchEntities", ["cancelSearch"]),
    ...mapActions("searchEntities", ["searchEntitiesByListOfTypes"]),
    customFilter() {
      return true;
    },
    clear() {
      this.cancelSearch();
      this.isLoading = false;
    },
    async filterEntities(filter) {
      this.numberOfLoadings++;
      this.isLoading = true;
      this.noResults = false;
      try {
        let result = await this.searchEntitiesByListOfTypes({
          filter: filter,
          types: ["ACCOUNT"],
        });
        if (result) {
          if (result.length == 0) {
            this.noResults = true;
          }
          this.searchItems = result;
        }
      } catch (err) {
        console.log(err);
      }
      this.numberOfLoadings--;
      if (this.numberOfLoadings == 0) {
        this.isLoading = false;
      }
    },
    checkLength() {
      if (
        this.search !== undefined &&
        this.search !== null &&
        this.search.length >= 99
      ) {
        this.search = this.search.slice(0, 99);
      }
    }
  }
};
</script>
