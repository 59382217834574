<template>
  <v-container>
    <v-card class="ml-3 px-2 elevation-0">
      <v-card-title class="justify-center">Link Accounts to Account Group</v-card-title>
      <account-search
        class="ml-2 mr-2"
        :selectNew="selectNew"
        :theme="colorTheme"
        searchLabel="Find accounts to link"
      ></account-search>
      <v-card class="mt-2" v-if="accounts.length > 0">
        <v-card-title class="justify-center">Linked Accounts</v-card-title>
        <v-text-field
          class="ml-2 mr-2"
          v-model="search"
          append-icon="mdi-magnify"
          label="Filter linked accounts"
          clearable
          single-line
          hide-details
          :color="colorTheme.comboBox"
        ></v-text-field>
        <v-data-table
          fixed-header
          class="scrollable-table__medium"
          :search="search"
          :items="accounts"
          :headers="accountsHeaders"
          item-key="id"
          :hide-default-footer="accounts.length <= 10"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="unlinkAccount(item)" class="delete-icon">
              mdi-link-variant-off
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import AccountSearch from "@/components/common/AccountSearch.vue";

export default {
  components: { AccountSearch },
  data: () => ({
    search: "",
    accountsHeaders: [
      {
        text: "Account Name",
        value: "name"
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false
      }
    ]
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("modifyEntityGroup", ["groupAccounts"]),
    accounts: {
      get() {
        return this.groupAccounts;
      },
      set(newValue) {
        this.setGroupAccounts(newValue);
      }
    }
  },
  methods: {
    ...mapMutations("modifyEntityGroup", [
      "setGroupModified",
      "setGroupAccounts"
    ]),
    selectNew(val) {
      if (val !== undefined && val !== null && val.id !== undefined) {
        let accountIds = this.accounts.map(a => a.id);
        if (!accountIds.includes(val.id)) {
          this.accounts.push({
            name: val.name,
            id: val.id
          });
          this.setGroupModified(true);
        }
      }
    },
    unlinkAccount(val) {
      this.accounts = this.accounts.filter(a => a.id !== val.id);
      this.setGroupModified(true);
    }
  },
  beforeDestroy() {
    this.accounts = [];
  }
};
</script>

<style scoped>
.delete-icon:hover {
  color: var(--v-error-base);
}

</style>
