function minimumCharactersText(isLoading, searchText, minLength) {
  return (
    isLoading !== undefined &&
    !isLoading &&
    searchText !== null &&
    searchText !== undefined &&
    searchText.length > 0 &&
    searchText.length < minLength
  );
}

function maximumCharactersText(searchText, maxLength) {
  return (
    searchText !== null &&
    searchText !== undefined &&
    searchText.length == maxLength
  );
}

function noResultsText(isLoading, searchText, items) {
  return (
    isLoading !== undefined &&
    !isLoading &&
    searchText !== null &&
    searchText !== undefined &&
    searchText !== "" &&
    items.length == 0
  );
}

//Check if input contains minLength  letters or numbers
function lettersAndNumbersCheck(isLoading, searchText, minLength) {
  if (
    searchText === undefined ||
    searchText === null ||
    searchText === "" ||
    isLoading === undefined ||
    isLoading
  ) {
    return false;
  } else {
    return !validCharacterCheck(searchText, minLength);
  }
}

function validCharacterCheck(input, minLength) {
  if (!input) {
    return false;
  }
  let regex = /[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]/]/gi;
  let characterFilter = input.replace(regex, '');
  let value = characterFilter.trim();
  return value.length >= minLength;
}

module.exports = {
  minimumCharactersText,
  maximumCharactersText,
  noResultsText,
  lettersAndNumbersCheck,
  validCharacterCheck
};
