<template>
  <v-container fluid>
    <v-row align="start" align-content="start" justify="start">
      <v-col cols="6">
        <new-group></new-group>
      </v-col>
      <v-col cols="6">
        <group-accounts-selector></group-accounts-selector>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewGroup from "@/components/account-groups/NewGroup.vue";
import GroupAccountsSelector from "@/components/account-groups/GroupAccountsSelector.vue";
import { mapActions } from "vuex";

export default {
  components: {
    NewGroup,
    GroupAccountsSelector
  },
  props: ["hierarchy", "id"],
  methods: {
    ...mapActions("browseEntities", ["setDefaultValues"])
  },
  async mounted() {
    await this.setDefaultValues({
      tabName: "AddGroup",
      hierarchy: this.hierarchy,
      id: this.id
    });
  }
};
</script>
