<template>
  <v-container fluid>
    <v-card class="ml-3 px-2 elevation-0">
      <v-card-title>Add Account Group</v-card-title>
      <v-form ref="form" v-model="valid">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name"
                v-model="formEdit.name"
                :rules="rules.name"
                required
                dense
                filled
                :color="colorTheme.toggle"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="External ID"
                v-model="formEdit.xref"
                dense
                filled
                :color="colorTheme.toggle"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-switch
                label="New"
                v-model="formEdit.isNew"
                :color="colorTheme.toggle"
              ></v-switch>
            </v-col>
            <v-col>
              <v-text-field
                label="Created By"
                v-model="formEdit.createdBy"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="unsavedFormValue"
              :style="colorTheme.textColor"
              :color="colorTheme.buttonWhite"
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="!disableConfirmButton"
              dark
              :color="colorTheme.button"
              @click="save"
            >
              Add Account Group
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data: () => ({
    valid: false,
    formEdit: {
      name: "",
      xref: "",
      isNew: false,
      createdAt: "",
      createdBy: ""
    },
    defaultForm: {
      name: "",
      xref: "",
      isNew: false,
      createdAt: "",
      createdBy: ""
    },
    rules: {
      name: [(v) => !!v || "Name is required"],
    }
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("auth", ["user"]),
    ...mapState("directoryNavigation", ["navigation"]),
    ...mapState("modifyEntityGroup", ["groupAccounts"]),
    ...mapState("addAccountGroup", ["resetFormValue"]),
    disableConfirmButton() {
      return !this.valid;
    },
    unsavedFormValue() {
      return (
        (this.formEdit.name && this.formEdit.name !== this.defaultForm.name) ||
        (this.formEdit.xref && this.formEdit.xref !== this.defaultForm.xref) ||
        (this.formEdit.isNew && this.formEdit.isNew != this.defaultForm.isNew) ||
        this.groupAccounts.length > 0
      );
    },
    formReset() {
      return this.resetFormValue;
    }
  },
  watch: {
    unsavedFormValue(val) {
      this.setUnsavedInputValue(val);
    },
    formReset(val) {
      if (val) {
        this.cancel();
        this.setResetFormValue(false);
      }
    }
  },
  methods: {
    ...mapMutations("modifyEntityGroup", ["setGroupAccounts"]),
    ...mapMutations("addAccountGroup", [
      "setUnsavedInputValue",
      "setResetFormValue"
    ]),
    ...mapActions("directoryNavigation", ["jumpToNavigationItem"]),
    ...mapActions("modifyEntityGroup", ["insertEntity"]),
    ...mapActions("entityActions", ["setTab"]),
    async save() {
      if (this.validate()) {
        try {
          let parentId = this.navigation[this.navigation.length - 1].id;
          let lastGroupNavigation = this.navigation[this.navigation.length - 1];
          let payload = {
            parentId: parentId,
            name: this.formEdit.name,
            type: "ACCOUNT_GROUP",
            xref: this.formEdit.xref,
            isNew: this.formEdit.isNew
          };
          let accounts = this.groupAccounts.map(a => a.id);
          payload.accounts = accounts;
          await this.insertEntity(payload);

          this.cancel();
          await this.jumpToNavigationItem(lastGroupNavigation);
          await this.setTab("HierarchyTree");
        } catch (err) {
          console.log(err);
        }
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.formEdit = Object.assign({}, this.defaultForm);
      this.setGroupAccounts([]);
      if (this.user != null) {
        this.formEdit.createdBy = this.user.username;
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
  async mounted() {
    if (this.user != null) {
      this.formEdit.createdBy = this.user.username;
    }
  }
};
</script>
